import React from 'react';

import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { enableChat } from '@pharmaplan/common';

import useHandleBookingCancel from '../../hooks/useHandleBookingCancel';
import { useAppSelector } from '../../hooks/useAppSelector';
import NavToolBar from '../Navbar/NavToolBar';

import FloatingChat from '../FloatingChat';
import Navbar from '../Navbar';
import classes from './style';

const { mainContainer, content, contentContainer } = classes;

const Home = () => {
  useHandleBookingCancel();
  const isChatEnabled = useAppSelector(enableChat);

  return (
    <Box sx={mainContainer} component="div">
      <Grid sx={contentContainer} item sm={11}>
        {/* TopBar */}
        <Box component="div">
          <Navbar BottomToolbar={<NavToolBar />} />
        </Box>
        {/* Content */}
        <Box sx={content}>
          <Outlet />
        </Box>
        {isChatEnabled && <FloatingChat />}
      </Grid>
    </Box>
  );
};

export default Home;
