/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import MessageContentSend, {
  MessageContentProps,
} from '@sendbird/uikit-react/ui/MessageContent';

import SenderProfile from './SenderProfile';

const MessageContent = (props: MessageContentProps) =>
  (
    <MessageContentSend {...props} renderSenderProfile={SenderProfile} />
  );

export default MessageContent;
