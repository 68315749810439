import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import ChatController from '../controllers/ChatController';
import { TypeOfUser } from '../helpers/Constants';
import { ISendbirdGroupAddMetadata } from '../models/ChatModels';
import { statusDispatch } from './globalActions';

const chat = 'chat';

export const chatActions = {
  createGroupChannel: `${chat}/channel/create`,
  groupChannelList: `${chat}/channel/list`,
  addGroupChannelMetadata: `${chat}/channel/metadata`,
  adminCreateGroupChannel: `${chat}/channel/admin/create`,
  clientUnreadCount: `${chat}/unread/count`,
  adminUnreadCount: `${chat}/admin/unread/count`,
  adminCloseChat: `${chat}/admin/close/chat`,
  chatPreLogout: `${chat}/pre/logout`,
  assignedChannels: `${chat}/assigned/channels`,
};

export const createGroupChannel = createAsyncThunk(
  chatActions.createGroupChannel,
  async (
    userType: Omit<
      TypeOfUser,
      TypeOfUser.admin | TypeOfUser.pharmacyAdmin | TypeOfUser.superAdmin
    >,
    { rejectWithValue }
  ) => {
    try {
      const resp = await ChatController.createGroupChannel(userType);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreateGroupChannel = createAsyncThunk(
  chatActions.adminCreateGroupChannel,
  async (userId: string, { rejectWithValue }) => {
    try {
      const resp = await ChatController.adminCreateGroupChannel(userId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addGroupChannelMetadata = createAsyncThunk(
  chatActions.addGroupChannelMetadata,
  async (params: ISendbirdGroupAddMetadata, { rejectWithValue }) => {
    try {
      const resp = await ChatController.addGroupMetadata(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const clientUnreadCount = createAsyncThunk(
  chatActions.clientUnreadCount,
  async (
    userType: Omit<
      TypeOfUser,
      TypeOfUser.admin | TypeOfUser.pharmacyAdmin | TypeOfUser.superAdmin
    >,
    { rejectWithValue }
  ) => {
    try {
      const resp = await ChatController.clientGetUnread(userType);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUnreadCount = createAsyncThunk(
  chatActions.adminUnreadCount,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await ChatController.adminGetUnread();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCloseChat = createAsyncThunk(
  chatActions.adminCloseChat,
  async (groupUrl: string, { rejectWithValue }) => {
    try {
      const resp = await ChatController.adminCloseChat(groupUrl);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const chatPreLogout = createAsyncThunk(
  chatActions.chatPreLogout,
  async (groupUrls: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await ChatController.chatPreLogout(groupUrls);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addAssignedChannels = createAction<Array<string>>(
  chatActions.assignedChannels
);
