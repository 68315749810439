import React, { FC } from 'react';

import { Button, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { enableChat } from '@pharmaplan/common';

import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setPrimaryRoute } from '../../../../actions/navbarActions';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { Constants } from '../../../../helpers/Constants';
import { PrimaryItem } from '../../Home/AdminHome';
import useAdmin from '../../../../hooks/useAdmin';

import { navigationConfig } from '../config';
import ChatCount from './ChatCount';
import styles from './styles';

interface IPrimaryToolbar {
  defaultClick: (item: PrimaryItem) => () => void;
}

const PrimaryToolbar: FC<IPrimaryToolbar> = ({ defaultClick }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { can } = useAdminPermissions();
  const { isSuperAdmin } = useAdmin();
  const isChatEnabled = useAppSelector(enableChat);

  return (
    <Toolbar sx={styles.toolbar}>
      {navigationConfig(can, isSuperAdmin, isChatEnabled).map((item) => {
        const isChat = item.path === Constants.paths.admin.adminChat;
        const isActive = item.path === pathname || item.subAbsolutePaths?.includes(pathname);
        if (isActive) {
          dispatch(setPrimaryRoute(item.key));
        }
        return (
          <Button
            onClick={defaultClick(item)}
            sx={[
              styles.navButton,
              isActive ? styles.activeButton : styles.inActive,
            ]}
            variant="text"
            key={item.key}
          >
            {item.label}
            {isChat && <ChatCount />}
          </Button>
        );
      })}
    </Toolbar>
  );
};
export default PrimaryToolbar;
