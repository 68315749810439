import { PermissionsOfAdmin } from '@pharmaplan/common';

import {
  PrimaryAdminNavigation,
  Constants,
  SecondaryAdminNavigation,
} from '../../../helpers/Constants';
import strings from '../../../localization';

const {
  adminCalendarDashboard,
  contactPreferences,
  pharmacyChains,
  pharmacistList,
  pharmacistCapacities,
  pharmacyList,
  incompatibleMatch,
  pendingActivationListPharmacist,
  pendingActivationListPharmacy,
  pendingActivationListDeletedUsers,
  pendingCancellation,
  updateIncompatibleMatch,
  bookingReport,
  pharmaplanStatistics,
  pendingActivationList,
  adminConfiguration,
  softwareList,
  availabilityReport,
  postedWorkshiftReport,
  requestedReport,
  createNewAdmin,
  createMultiple,
  updateAdmin,
  administratorList,
  listOfWorkshifts,
  adminMapDashboard,
  pharmacyListIncompatible,
  pharmacyListIncompatibleUpdate,
  viewRatings,
  broadcastList,
  createBroadcastGroup,
  editBroadcastGroup,
  createNotification,
  editNotification,
  activityLogs,
  workshiftLogs,
  bookingLogs,
  availabilityLogs,
  broadcastListGroups,
  broadcastListNotification,
  pharmacyAdministrators,
  createPharmacyAdministrators,
  updatePharmacyAdministrators,
  appointPharmacyAdministrators,
  appointPharmacyList,
  adminChat,
} = Constants.paths.admin;

export const navigationConfig = (
  can: (params: PermissionsOfAdmin) => boolean,
  isSuperAdmin: boolean,
  isChatEnabled: boolean,
) => {
  const cannotViewAdministratorList = !can(
    PermissionsOfAdmin.AdministratorList,
  );
  const cannotViewLogs = !can(PermissionsOfAdmin.LogsView);
  const canViewExpressBooking = can(PermissionsOfAdmin.ExpressBooking);
  const canViewGlobalSettings = can(PermissionsOfAdmin.GlobalSetting);

  const dashboardSub = [
    SecondaryAdminNavigation.pharmacistsList,
    SecondaryAdminNavigation.pharmacyList,
    SecondaryAdminNavigation.expressBooking,
    SecondaryAdminNavigation.pendingActivation,
    SecondaryAdminNavigation.pendingCancellation,
    SecondaryAdminNavigation.incompatibleMatch,
  ];

  let adminListSub = [
    SecondaryAdminNavigation.administratorList,
    SecondaryAdminNavigation.broadcastNotifications,
    SecondaryAdminNavigation.pharmacyAdministrators,
    SecondaryAdminNavigation.pharmaplanStatistics,
  ];

  if (cannotViewAdministratorList) {
    adminListSub = adminListSub.filter(
      (item) =>
        item !== SecondaryAdminNavigation.administratorList,
    );
  }

  const configurationSub = [
    SecondaryAdminNavigation.pharmacistsCapacities,
    SecondaryAdminNavigation.softwareList,
    SecondaryAdminNavigation.pharmacyChains,
    SecondaryAdminNavigation.contactPreferences,
    SecondaryAdminNavigation.adminConfiguration,
  ];

  let config = [
    {
      key: PrimaryAdminNavigation.dashboard,
      label: strings.dashboard,
      path: adminCalendarDashboard,
      subPaths: canViewExpressBooking
        ? dashboardSub
        : dashboardSub.filter(
          (item) =>
            item !== SecondaryAdminNavigation.expressBooking,
        ),
      subAbsolutePaths: [
        pharmacistList,
        createMultiple,
        pharmacyList,
        listOfWorkshifts,
        pharmacyListIncompatible,
        pharmacyListIncompatibleUpdate,
        viewRatings,
        pendingActivationList,
        pendingCancellation,
        adminMapDashboard,
        incompatibleMatch,
        updateIncompatibleMatch,
        pendingActivationListPharmacy,
        pendingActivationListPharmacist,
        pendingActivationListDeletedUsers,
      ],
    },
    {
      key: PrimaryAdminNavigation.admin,
      label: strings.admin,
      path: cannotViewAdministratorList ? broadcastList : administratorList,
      subPaths: adminListSub,
      subAbsolutePaths: [
        administratorList,
        createNewAdmin,
        updateAdmin,
        broadcastList,
        createBroadcastGroup,
        editBroadcastGroup,
        createNotification,
        editNotification,
        broadcastListGroups,
        broadcastListNotification,
        pharmacyAdministrators,
        createPharmacyAdministrators,
        updatePharmacyAdministrators,
        appointPharmacyAdministrators,
        appointPharmacyList,
        pharmaplanStatistics,
      ],
    },
    {
      key: PrimaryAdminNavigation.reports,
      label: strings.reports,
      path: bookingReport,
      subPaths: [
        SecondaryAdminNavigation.bookingReport,
        SecondaryAdminNavigation.availabilityReport,
        SecondaryAdminNavigation.requestedReport,
        SecondaryAdminNavigation.postedWorkshiftsReport,
      ],
      subAbsolutePaths: [
        bookingReport,
        availabilityReport,
        requestedReport,
        postedWorkshiftReport,
      ],
    },
    {
      key: PrimaryAdminNavigation.logs,
      label: strings.logs,
      path: activityLogs,
      subPaths: [
        SecondaryAdminNavigation.activityLogs,
        SecondaryAdminNavigation.workshiftLogs,
        SecondaryAdminNavigation.availabilityLogs,
        SecondaryAdminNavigation.bookingLogs,
      ],
      subAbsolutePaths: [
        activityLogs,
        workshiftLogs,
        availabilityLogs,
        bookingLogs,
      ],
    },
    {
      key: PrimaryAdminNavigation.configuration,
      label: strings.configuration,
      path: pharmacistCapacities,
      subPaths:
        isSuperAdmin || canViewGlobalSettings
          ? configurationSub
          : configurationSub.filter(
            (item) =>
              SecondaryAdminNavigation.adminConfiguration !== item,
          ),
      subAbsolutePaths: [
        Constants.paths.admin.softwareList,
        Constants.paths.admin.pharmacistCapacities,
        contactPreferences,
        Constants.paths.admin.adminConfiguration,
        pharmacyChains,
      ],
    },
    {
      key: PrimaryAdminNavigation.chat,
      label: 'Chat',
      path: adminChat,
      subPaths: [SecondaryAdminNavigation.chat],
      subAbsolutePaths: [adminChat],
    },

  ];

  if (cannotViewLogs) {
    config = config.filter((item) =>
      item.key !== PrimaryAdminNavigation.logs);
  }

  if (!isChatEnabled) {
    config = config.filter((item) =>
      item.key !== PrimaryAdminNavigation.chat);
  }

  return config;
};

export const secondaryNavigationConfig = (): {
  [key in SecondaryAdminNavigation]: {
    label: string;
    path?: string;
    subPath?: Array<string>;
  };
} =>
  ({
  // Dashboard
    [SecondaryAdminNavigation.pharmaplanStatistics]: {
      label: strings.pharmaplanStatistics,
      path: pharmaplanStatistics,
    },
    [SecondaryAdminNavigation.expressBooking]: {
      label: strings.expressBooking,
    },
    [SecondaryAdminNavigation.pendingActivation]: {
      label: strings.pendingActivation,
      path: Constants.paths.admin.pendingActivationList,
      subPath: [pendingActivationListPharmacy,
        pendingActivationListPharmacist,
        pendingActivationListDeletedUsers],
    },
    [SecondaryAdminNavigation.pendingCancellation]: {
      label: strings.pendingCancellation,
      path: pendingCancellation,
    },

    // Admin
    [SecondaryAdminNavigation.pharmacistsList]: {
      label: strings.pharmacists,
      path: pharmacistList,
      subPath: [createMultiple, viewRatings],
    },
    [SecondaryAdminNavigation.pharmacyList]: {
      label: strings.pharmacies,
      path: Constants.paths.admin.pharmacyList,
      subPath: [
        listOfWorkshifts,
        pharmacyListIncompatible,
        pharmacyListIncompatibleUpdate,
      ],
    },
    [SecondaryAdminNavigation.administratorList]: {
      label: strings.administrators,
      path: administratorList,
      subPath: [createNewAdmin, updateAdmin],
    },

    // Reports
    [SecondaryAdminNavigation.bookingReport]: {
      label: strings.bookingReport,
      path: bookingReport,
    },
    [SecondaryAdminNavigation.availabilityReport]: {
      label: strings.availabilityReport,
      path: availabilityReport,
    },
    [SecondaryAdminNavigation.requestedReport]: {
      label: strings.requestedReport,
      path: requestedReport,
    },
    [SecondaryAdminNavigation.postedWorkshiftsReport]: {
      label: strings.postedWorkshiftsReport,
      path: postedWorkshiftReport,
    },

    // Logs
    [SecondaryAdminNavigation.activityLogs]: {
      label: strings.activityLogs,
      path: activityLogs,
    },
    [SecondaryAdminNavigation.workshiftLogs]: {
      label: strings.workshiftLogs,
      path: workshiftLogs,
    },
    [SecondaryAdminNavigation.availabilityLogs]: {
      label: strings.availabilityLogs,
      path: availabilityLogs,
    },
    [SecondaryAdminNavigation.bookingLogs]: {
      label: strings.bookingLogs,
      path: bookingLogs,
    },

    // Configuration
    [SecondaryAdminNavigation.pharmacistsCapacities]: {
      label: strings.pharmacistsCapacities,
      path: pharmacistCapacities,
    },
    [SecondaryAdminNavigation.contactPreferences]: {
      label: strings.contact,
      path: contactPreferences,
    },
    [SecondaryAdminNavigation.pharmacyChains]: {
      label: strings.banners,
      path: pharmacyChains,
    },
    [SecondaryAdminNavigation.softwareList]: {
      label: strings.softwareSingular,
      path: softwareList,
    },
    [SecondaryAdminNavigation.adminConfiguration]: {
      label: strings.globalSettings,
      path: adminConfiguration,
    },
    [SecondaryAdminNavigation.broadcastNotifications]: {
      label: strings.broadcast,
      path: broadcastList,
      subPath: [
        createBroadcastGroup,
        editBroadcastGroup,
        createNotification,
        editNotification,
        broadcastListGroups,
        broadcastListNotification,
      ],
    },
    [SecondaryAdminNavigation.incompatibleMatch]: {
      label: strings.incompatibility,
      path: incompatibleMatch,
      subPath: [updateIncompatibleMatch],
    },
    [SecondaryAdminNavigation.pharmacyAdministrators]: {
      label: strings.pharmacyAdministrators,
      path: pharmacyAdministrators,
      subPath: [
        createPharmacyAdministrators,
        updatePharmacyAdministrators,
        appointPharmacyAdministrators,
        appointPharmacyList,
      ],
    },
    [SecondaryAdminNavigation.chat]: {
      label: strings.chat,
      path: adminChat,
    },
  });
